
import {Fragment} from 'react'
import Navbar from "../../organisms/Navbar";
import Footer from "../../organisms/Footer";
import {PageTitle} from "../../atoms/PageTitle";

export default function WhyShouldYouBoycott() {

        return (
            <>
                <PageTitle title="Why Should You Boytott Israel?"/>
                <Navbar key="navbar"/>
                <header className="bg-gray-100 h-full">
                    <div className="mx-auto py-1 px-7 h-full">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:mt-7 sm:text-4xl">Why Should You Boycott Israel?</h1>
                    </div>
                </header>
                <main className="bg-gray-100 h-full mb-11">
                    <div className="mx-auto py-4 px-7 h-full text-md leading-8 text-gray-600">
                        <p className="">
                            On October 7, 2023, Israel started mass bombardment in Gaza and Palestine (again).
                            It used the pretext of a Hamas bombing to start the bombardment, but this, of course, is a
                            result of Israel's occupation of Palestine since 1948 and its continued oppression of the
                            Palestinian people. This pretext is not
                            justifiable in any way,
                            shape, or form as Israel has forced the Palestinian
                            people into a corner and has forced them to fight back for over 7 decades. It's even more
                            absurd because Israel
                            has already killed 30,000 Palestinians in response
                            to the 1,100 it claims Hamas has killed. And it claims its civilians are innocent and the
                            Palestinians are not. Nothing more needs to be said about bad intentions or arrogance here.
                        </p>
                        <p className="mt-3">
                            Except now, Israel is conducting what can only be described as genocide. It is killing
                            innocent civilians out of
                            animosity and is committing crimes of oppression. Killing women and children is hardly a
                            sign of bravery or chivalry,
                            so any communication with the Zionist / Fascist Israeli regime to try and be reasonable
                            has simply fallen on deaf years.
                            The Zionist claims of holocaust and sympathy are, therefore, baseless as they are
                            committing the same holocaust themselves. Woe unto these wrongdoers.
                        </p>
                        <p className="mt-3">
                            Whether you are a <b>Jew, Christian, Muslim, Native American, Samoan, Buddhist,
                            Shintoist, Hindu,</b> or adhere to any other religion, you should
                            know that oppression against others is an unforgivable sin. I don't intend to quote
                            scripture from all the traditions here,
                            but <b>not speaking out against the oppressed and supporting the
                            the oppressors is the same as being an oppressor yourself. And there is no forgiveness for
                            this. In the end, ignorance will not be an excuse.</b>
                        </p>
                        <p className="mt-3">
                            As a Muslim, I will quote the Qur'an. <b>Surah 99, Verses 7 and 8</b> say:
                            <span class="block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                                And whoso doeth good an atom's weight will see it then. <br/>And whoso doeth ill an atom's weight will see it then.
                            </span>
                        </p>
                        <p className="mt-3">
                            Paying any money to an Israeli business that may find its way to the government or their
                            army that then leads to the murder
                            of an innocent child or person will definitely <i>be more than an atom's weight of ill</i>.
                            Fear God and fear His punishment. There is a
                            special place in hell for oppressors like the Israeli government, the Israeli army and their
                            supporters (like Joe Biden and the US government), so you have to make an effort to not be
                            one of them.
                        </p>
                        <p className="mt-3">
                            But how can this be, you might ask? Remember, that zionists and corrupt people generally
                            worship
                            the false god of money. If you stop their inflow of money, you can disable their wrongdoing,
                            plain and simple.
                            We will take 2 examples to illustrate how your use of Israeli products makes you a part of
                            the oppression.
                        </p>
                        <p className="mt-3">
                            Let's say you use <b>Redis</b>, a popular open source software for caching, You use the
                            free, open
                            source version to develop a library or tool, so
                            you do not think you are paying anything to Redis in Israel. But you are also building an
                            open source library, and some other company or person
                            picks it up, and rather than hosting Redis themselves, they decide to use Redis enterprise
                            with your library and start paying Redis money for this.
                            In turn, Redis uses this money to pay its employees, and some of these employees are Israeli
                            citizens. These Israeli citizens then pay taxes to the Israeli government,
                            and the Israeli government uses this money to buy weapons to kill innocent Palestinians. So
                            in this case, you have contributed to the oppression of the Palestinian people.
                            There is no getting away from it - you simply need to boycott all Israeli
                            businesses.
                        </p>
                        <p className="mt-3">
                            We can take another example like <b>McDonald's</b>, where the boycott is actually making a
                            difference.
                            As a rule, you should avoid eating all fast food anyway, it's not good for you. But let's
                            say that you
                            pay for McDonald's food in the US, which feeds their bottom line, which then goes to buying
                            materials
                            and goods which are then given to the Israeli Franchise, which then gives it away as free
                            meals to Israeli
                            soliders (something that has already happened) as
                            free meals, who then go about committing the murder of innocent Palestinians. Remember, we
                            are talking about
                            an <b>atom's</b> weight worth of bad here.
                        </p>
                        <p className="mt-3">
                            <i>You should make every effort to ensure that your money does end up in the hands of
                                wrongdoers, because
                                in the end, you will be held accountable for it. There is always a reckoning, and
                                boycotting all Israeli
                                products and companies is the best way ensure your hands and deeds are not tainted with
                                the blood of innocent people.</i>
                        </p>
                        <p className="mt-3">
                            <b>We call on those businesses who are not zionists or fascists to move their companies and
                                their businesses out of Israel,
                                and to not pay any money towards the murder of innocent people.</b>
                        </p>
                        <p className="mt-3">
                            And just for the record, there is nothing anti semitic about this. Arab Muslims are just as
                            semitic as Hebrew Jews are, a simple
                            fact that most politicians fail to see (or maybe being an American politician gives you
                            license to spread your ignorance). Zionists
                            are not just Jews, they are extremists, or state sponsored terrorists, at best, so let us
                            not mix the two.
                        </p>
                        <p className="mt-3">
                            And it is because of these Zionists that Israel will not know peace. Their foundation is
                            corrupt. Read <a href="/learn-why-israel-will-continue-in-turmoil"
                                             className="text-sm font-semibold leading-6 text-green-900 hover:text-red-600">
                            Learn why Israel is going to continue to be a nation in turmoil
                        </a> to find out more.
                        </p>
                        <p className="mt-3">
                            It is, however, important to know that Israel is not alone in these sins and crimes, even
                            though
                            the need to disable them is the most urgent. The United States is the biggest enabler and
                            facilitator
                            of war and oppression in the world. Just look at all the damage they have done in
                            Afghanistan, Iraq, and
                            Syria since 2001. American citizens have more blood on their hands than most other nations
                            do due to the
                            actions of their government. Then there is the United Kingdom, France, Germany, and other
                            European nations
                            who have been complicit in the oppression of the Palestinian people.
                        </p>
                        <p className="mt-3">
                            And then there is China and their oppression of the Uighur Muslims.
                        </p>
                        <p className="mt-3">And then there is India and their oppression of the Kashmiri Muslims.</p>
                        <p className="mt-3">And then there is Myanmar and their oppression of the Rohingya Muslims.</p>
                        <p className="mt-3">And then there is Russia and their oppression of the Chechen Muslims and
                            their war with the Ukranians.</p>
                        <p className="mt-3">Wherever you see oppression and wrong doing, it is your moral responsibility
                        to speak out against it, and if you cannot do that, use everything within your power to not contribute to it.</p>

                    </div>
                </main>
                <Footer/>
            </>
        );
};
